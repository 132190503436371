<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        :lazy-validation="lazy"
                        class="w-100">
                    <v-container fluid class="pa-8">
                        <dynoform @change="UpdateFormField($event, 'formdata')"
                                  @changeGrid="UpdateExtData($event, $event.tableName)"
                                  @click="FormButtonClicked($event)"
                                  :attributes="GetFormAttributes"
                                  @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                  @HeaderButtonClicked="HeaderButtonClickedDyno($event)"
                                  @changeAttribute="ChangeAttribute($event,'formdata')"
                                  :tabname="tab"
                                  :durum="durum"></dynoform>
                    </v-container>
                </v-form>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="!isgformsdataloaded" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="!isgformsdataloaded" class="primary--text overline">{{ $t("IM_RetrievingData") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-for="(action, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="PageAction(action)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="action.action">
                    {{ $t(action.label) }}
                </v-btn>
            </v-footer>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';

    Vue.use(Vuex);

    var Tedarik_Firma_Hesap = {
        tableName: 'Tedarik_Firma_Hesap',
        validationMessage: 'VE_WarnFillFirmaHesapTable',
        label: 'TH_BankAccountInformation',
        columns: {
            banka: {
                type: 'auto',
                name: 'banka',
                label: 'FL_BankName',
                edit: '01234X',
                parameters: [],
                outputs: {
                    bankakodu: 'bankakodu'
                },
                itemText: ['bankaadi'],
                searchText: 'bankaadi',
                validateMin: 3,
                minlenght: 1,
            },
            bankakodu: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankakodu',
                width: 0
            },
            iban: {
                type: 'text',
                name: 'iban',
                label: 'FL_IBAN',
                edit: '01234X',
                validateMin: 26,
                validateMax: 26,
            },
            paracinsi: {
                type: 'select',
                name: 'paracinsi',
                label: 'FL_Currency',
                edit: '01234X',
                options: [
                    { text: 'TL', value: 'TL' },
                    { text: 'USD', value: 'USD' },
                    { text: 'EUR', value: 'EUR' },
                    { text: 'GBP', value: 'GBP' },
                    { text: 'QAR', value: 'QAR' }
                ],
                validateMin: 2,
            },
            defaultHesap: {
                type: 'select',
                name: 'defaultHesap',
                label: 'FL_DefaultAccount',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
                validateMax: 1,
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            bankahesapno: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankahesapno',
                width: 0
            },
            musterino: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'musterino',
                width: 0
            },
            ilad: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'ilad',
                width: 0
            },
            bankail: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankail',
                width: 0
            },
            bankasube: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankasube',
                width: 0
            },
            bankasubeno: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankasubeno',
                width: 0
            },
            isValidated: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'isValidated',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                defaultHesap: { items: [], error: false, readonly: false, hidden: false },
                paracinsi: { items: [], error: false, readonly: false, hidden: false },
                IBAN: { items: [], error: false, readonly: false, hidden: false },
                banka: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Firma_Hesap'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };


    var Tedarik_Firma_Swift_Hesap = {
        tableName: 'Tedarik_Firma_Swift_Hesap',
        validationMessage: 'VE_WarnFillSwiftFirmaHesapTable',
        label: 'TH_BankAccountSwiftInformation',
        columns: {
            bankaUlkeAdi: {
                type: 'auto',
                group: 2,
                sira: 60,
                col: '4',
                name: 'bankaUlkeAdi',
                label: 'FL_Country',
                edit: '01234X',
                parameters: [],
                itemText: ['ulke'],
                searchText: 'ulke',
                validateMin: 1,
                minLength: 1,
                notAuthorizedRequest: true,
                queryName: 'tedarik_ulke'
            },
            banka: {
                type: 'text',
                name: 'banka',
                label: 'FL_BankName',
                edit: '01234X',
                validateMin: 1,
            },
            iban: {
                type: 'text',
                name: 'iban',
                label: 'FL_IBAN',
                edit: '01234X',
                validateMin: 1,
                validateMax: 34,
            },
            swift: {
                type: 'text',
                name: 'swift',
                label: 'FL_SWIFT',
                edit: '01234X',
                validateMin: 1,
            },
            paracinsi: {
                type: 'select',
                name: 'paracinsi',
                label: 'FL_Currency',
                edit: '01234X',
                options: [
                    { text: 'TL', value: 'TL' },
                    { text: 'USD', value: 'USD' },
                    { text: 'EUR', value: 'EUR' },
                    { text: 'GBP', value: 'GBP' },
                    { text: 'QAR', value: 'QAR' }
                ],
                validateMin: 2,
            },
            defaultHesap: {
                type: 'select',
                name: 'defaultHesap',
                label: 'FL_DefaultAccount',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
                validateMax: 1,
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            bankakodu: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankakodu',
                width: 0
            },
            bankahesapno: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankahesapno',
                width: 0
            },
            musterino: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'musterino',
                width: 0
            },
            ilad: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'ilad',
                width: 0
            },
            bankail: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankail',
                width: 0
            },
            bankasube: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankasube',
                width: 0
            },
            bankasubeno: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'bankasubeno',
                width: 0
            },
            isValidated: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'isValidated',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                defaultHesap: { items: [], error: false, readonly: false, hidden: false },
                paracinsi: { items: [], error: false, readonly: false, hidden: false },
                IBAN: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Firma_Swift_Hesap'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Ortaklar = {
        tableName: 'Tedarik_Uyum_Ortaklar',
        validationMessage: 'VE_WarnFillShareholdingStructure',
        label: 'TH_PartnershipStructure',
        columns: {
            ortak: {
                type: 'text',
                name: 'ortak',
                label: 'FL_Partner',
                edit: '01234X',
                validateMin: 1,
            },
            oran: {
                type: 'number',
                name: 'oran',
                label: 'FL_Ratio',
                edit: '01234X',
                validateMin: 0.01,
            },
            tckn: {
                type: 'text',
                name: 'tckn',
                label: 'FL_VKNorTCKN',
                edit: '01234X',
                validateMin: 1,
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Ortaklar'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Grup_Firmalari = {
        tableName: 'Tedarik_Uyum_Grup_Firmalari',
        label: 'TH_GroupCompaniesIfAny',
        columns: {
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_CompanyTitle',
                edit: '01234X',
                validateMin: 1,
            },
            tckn: {
                type: 'text',
                name: 'tckn',
                label: 'FL_VKNorTCKN',
                edit: '01234X',
                validateMin: 1,
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Grup_Firmalari'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Temsilciler = {
        tableName: 'Tedarik_Uyum_Temsilciler',
        validationMessage: 'VE_WarnFillPersonsAuthorizedRepresent',
        label: 'TH_PersonsAuthorizedRepresent',
        columns: {
            adsoyad: {
                type: 'text',
                name: 'ad',
                label: 'FL_Fullname',
                edit: '01234X',
                validateMin: 1,
            },
            departmentId: {
                type: 'select',
                name: 'departmentId',
                label: 'FL_Department',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },
            phone: {
                type: 'text',
                name: 'phone',
                label: 'FL_PhoneNumber',
                edit: '01234X',
                validateMin: 1,
                mask: 'phone',
            },
            email: {
                type: 'text',
                name: 'email',
                label: 'FL_Email',
                edit: '01234X',
                validateMin: 1,
                mask: 'email',
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Temsilciler'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Uyum_Yonetim_Yapisi = {
        tableName: 'Tedarik_Uyum_Yonetim_Yapisi',
        validationMessage: 'VE_WarnFillManagementStructure',
        label: 'TH_ManagementStructure',
        columns: {
            adsoyad: {
                type: 'text',
                name: 'adsoyad',
                label: 'FL_Fullname',
                edit: '01234X',
                validateMin: 1,
            },
            gorev: {
                type: 'text',
                name: 'gorev',
                label: 'FL_Position',
                edit: '01234X',
                validateMin: 1,
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Tedarik_Uyum_Yonetim_Yapisi'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Biggest_Customers = {
        tableName: 'Tedarik_Biggest_Customers',
        validationMessage: 'VE_WarnFillBiggestCustomers',
        label: 'TH_BiggestCustomers',
        columns: {
            firmName: {
                type: 'text',
                name: 'firmName',
                label: 'FL_CompanyTitle',
                edit: '01234X',
                validateMin: 1,
            },
            scope: {
                type: 'text',
                name: 'scope',
                label: 'FL_Scope',
                edit: '01234X',
                validateMin: 1,
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'tedarik_biggest_customers'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Tedarik_Biggest_Financial_Customers = {
        tableName: 'Tedarik_Biggest_Financial_Customers',
        label: 'TH_FinancialCustomers',
        columns: {
            firmName: {
                type: 'text',
                name: 'firmName',
                label: 'FL_CompanyTitle',
                edit: '01234X',
                validateMin: 1,
            },
            scope: {
                type: 'text',
                name: 'scope',
                label: 'FL_Scope',
                edit: '01234X',
                validateMin: 1,
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'tedarik_biggest_financial_customers'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var tedarik_insurances = {
        tableName: 'tedarik_insurances',
        label: 'TH_Insurances',
        columns: {
            insuranceNumber: {
                type: 'text',
                name: 'insuranceNumber',
                label: 'FL_InsuranceNumber',
                edit: '01234X',
                validateMin: 1,
            },
            location: {
                type: 'text',
                name: 'location',
                label: 'FL_Location',
                edit: '01234X',
                validateMin: 1,
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                ortak: { items: [], error: false, readonly: false, hidden: false },
                oran: { items: [], error: false, readonly: false, hidden: false },
                tckn: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'tedarik_insurances'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var dynoFormInfo = {
        tabs: [
            { order: 1, header: 'TH_GeneralInformation', nextButtonId: '2' },
            { order: 2, header: 'TH_CompanyInformation', previousButtonId: '1', nextButtonId: '3'  },
            { order: 3, header: 'TH_BankAccountInformation', previousButtonId: '2' },
        ],
        TabAttributes: {
            "1": { show: true},
            "2": { show: true},
            "3": { show: true },
        },
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_GeneralInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_ContactInformation'
            },
            {
                tab: 2,
                order: 4,
                header: 'TH_CompanyInformation'
            },
            {
                tab: 3,
                order: 3,
                header: 'TH_RegisteredAccounts'
            },
            {
                tab: 1,
                order: 5,
                header: 'TH_PersonnelInformation'
            },
        ],

        Columns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'id',
                label: 'ID',
                edit: '',
                dontCompare: true,
            },
            kformsid: {
                type: 'hidden',
                group: 1,
                sira: 0,
                name: 'kformsid',
                label: 'GFORMS ID',
                edit: '',
                dontCompare: true,
            },
            firmaad: {
                type: 'text',
                name: 'firmaad',
                label: 'FL_CompanyTitle',
                group: 1,
                sira: 10,
                col: '12',
                edit: '01234X',
            },
            yurtici: {
                type: 'select',
                name: 'yurtici',
                label: 'FL_WhichBased',
                group: 1,
                sira: 20,
                col: '6',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                //validateMin: 1,
                validateMax: 1,
            },
            sahis: {
                type: 'select',
                name: 'sahis',
                label: 'FL_IsSole',
                group: 1,
                sira: 30,
                col: '6',
                edit: '01234X',
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
                validateMax: 1,
            },
            verginum: {
                type: 'text',
                name: 'verginum',
                label: 'FL_VKNorTCKN',
                group: 1,
                sira: 40,
                col: '6',
                edit: '01234X',
                validateMin: 10,
                validateMax: 11,
            },
            vergidaire: {
                type: 'text',
                name: 'vergidaire',
                label: 'FL_TaxOffice',
                group: 1,
                sira: 42,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            tedarikcitipi: {
                type: 'select',
                name: 'tedarikcitipi',
                label: 'FL_SupplierSector',
                group: 1,
                sira: 44,
                col: '6',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },
            kategorikey: {
                type: 'select',
                name: 'kategorikey',
                label: 'FL_SupplierCategory',
                group: 1,
                sira: 47,
                col: '6',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },
            tedarikcikullanimi: {
                type: 'select',
                name: 'tedarikcikullanimi',
                label: 'FL_SupplierType',
                group: 1,
                sira: 48,
                col: '3',
                edit: '01234X',
                options: [
                ],
                validateMin: 1,
                validateMax: 1,
            },

            kurulusyili: {
                type: 'int',
                name: 'kurulusyili',
                label: 'FL_FoundationYear',
                group: 1,
                sira: 50,
                col: '3',
                edit: '01234X',
                validateMin: 1,
            },
            stockInExchangeMarketFlag: {
                type: 'select',
                name: 'stockInExchangeMarketFlag',
                label: 'FL_StockInExchangeMarketFlag',
                group: 1,
                sira: 51,
                col: '3',
                edit: '01234X',
                options: [
                ],
            },
            stockInExchangeMarketPercentage: {
                type: 'number',
                group: 1,
                sira: 52,
                col: '3',
                name: 'stockInExchangeMarketPercentage',
                label: 'FL_StockInExchangeMarketPercentage',
                edit: '01234X',
            },
            sonyilcirosu: {
                type: 'number',
                name: 'sonyilcirosu',
                label: 'FL_NetSalesLastYear',
                group: 1,
                sira: 53,
                col: '4',
                edit: '01234X',
                validateMin: 1,
            },
            sonyilcirosubirimi: {
                type: 'select',
                name: 'sonyilcirosubirimi',
                label: 'FL_Currency',
                edit: '01234X',
                group: 1,
                col: '2',
                sira: 54,
                options: [
                    { text: 'TL', value: 'TL' },
                    { text: 'USD', value: 'USD' },
                    { text: 'EUR', value: 'EUR' },
                    { text: 'GBP', value: 'GBP' },
                    { text: 'QAR', value: 'QAR' }
                ],
                validateMin: 2,
            },
            ikiYilOnceninCirosu: {
                type: 'number',
                name: 'ikiYilOnceninCirosu',
                label: 'FL_NetSalesTwoYearAgo',
                group: 1,
                sira: 55,
                col: '4',
                edit: '01234X'
            },
            ikiYilOnceninCirosubirimi: {
                type: 'select',
                name: 'ikiYilOnceninCirosubirimi',
                label: 'FL_Currency',
                edit: '01234X',
                group: 1,
                col: '2',
                sira: 56,
                options: [
                    { text: 'TL', value: 'TL' },
                    { text: 'USD', value: 'USD' },
                    { text: 'EUR', value: 'EUR' },
                    { text: 'GBP', value: 'GBP' },
                    { text: 'QAR', value: 'QAR' }
                ]
            },
            legalBondWithCooperation: {
                type: 'text',
                name: 'legalBondWithCooperation',
                label: 'FL_LegalBondWithCooperation',
                group: 1,
                sira: 57,
                col: '12',
                edit: '01234X',
            },
            faaliyetalani: {
                type: 'textarea',
                group: 1,
                sira: 59,
                col: '12',
                name: 'faaliyetalani',
                label: 'FL_ActivtyArea',
                edit: '01234X',
                validateMin: 1,
            },
            references: {
                type: 'textarea',
                group: 1,
                sira: 59,
                col: '12',
                name: 'references',
                label: 'FL_References',
                edit: '01234X',
                validateMin: 1,
            },
            scopeOfStrategicPartners: {
                type: 'textarea',
                group: 1,
                sira: 59,
                col: '6',
                name: 'scopeOfStrategicPartners',
                label: 'TH_ScopeOfStrategicPartners',
                edit: '01234X'
            },
            rdBudget: {
                type: 'textarea',
                group: 1,
                sira: 59,
                col: '6',
                name: 'rdBudget',
                label: 'FL_RnDBudget',
                edit: '01234X'
            },
            vergilevhasifile: {
                type: 'file',
                name: 'vergilevhasifile',
                label: 'FL_TaxBoard',
                group: 1,
                sira: 60,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            ticaretsicilgazetesifile: {
                type: 'file',
                name: 'ticaretsicilgazetesifile',
                label: 'FL_TradeRegistryNewspaper',
                group: 1,
                sira: 62,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            imzasirkulerifile: {
                type: 'file',
                name: 'imzasirkulerifile',
                label: 'FL_SignatureCircus',
                group: 1,
                sira: 63,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            faaliyetbelgesifile: {
                type: 'file',
                name: 'faaliyetbelgesifile',
                label: 'FL_ActivityCertificate',
                group: 1,
                sira: 65,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            kimlikfotokopisifile: {
                type: 'file',
                name: 'kimlikfotokopisifile',
                label: 'FL_PhotocopyID',
                group: 1,
                sira: 67,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            gizliliksozlesmesifile: {
                type: 'file',
                name: 'gizliliksozlesmesifile',
                label: 'FL_PrivacyAggrement',
                draft: 'DM_PrivacyAggrementLink',
                group: 1,
                sira: 68,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            mukimlikbelgesifile: {
                type: 'file',
                name: 'mukimlikbelgesifile',
                label: 'FL_TaxPayerID',
                group: 1,
                sira: 69,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            ekbelgefile: {
                type: 'file',
                name: 'ekbelgefile',
                label: 'FL_AdditionalFiles',
                group: 1,
                sira: 70,
                col: '6',
                edit: '01234X',
                validateMin: 0,
                dontCompare: true,
            },
            qualityAssuranceandEmployeefile: {
                type: 'file',
                name: 'qualityAssuranceandEmployeefile',
                label: 'FL_QualityAssuranceandEmployeeFile',
                group: 1,
                sira: 75,
                col: '6',
                edit: '01234X',
                dontCompare: true,
            },
            IFCfile: {
                type: 'file',
                name: 'IFCfile',
                label: 'FL_IFCFile',
                draft: 'DM_IFCStandartsLink',
                draftLabel: 'AL_ShowFile',
                group: 1,
                sira: 75,
                col: '6',
                edit: '01234X',
                validateMin: 1,
                dontCompare: true,
            },
            IFCApprove: {
                type: 'alert',
                messageType: 'info',
                message: 'DM_IFCStandartsDescription',
                group: 1,
                sira: 75,
                col: '12',
            },
            PrensipApprove: {
                type: 'confirmbox',
                name: 'PrensipApprove',
                document: 'DM_CompanyWorknBehavior',
                group: 1,
                sira: 75,
                col: '12',
                edit: '01234X',
                validateMin: 1,
                validateErrorMin: 'VE_CompanyWorknBehavior',
            },
            IFCfiledysid: {
                type: 'hidden',
                name: 'IFCfiledysid',
                group: 1,
                sira: 0,
                dontCompare: true,
            },
            vergilevhasifiledysid: {
                type: 'hidden',
                name: 'vergilevhasifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            ticaretsicilgazetesifiledysid: {
                type: 'hidden',
                name: 'ticaretsicilgazetesifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            imzasirkulerifiledysid: {
                type: 'hidden',
                name: 'imzasirkulerifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            faaliyetbelgesifiledysid: {
                type: 'hidden',
                name: 'faaliyetbelgesifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            kimlikfotokopisifiledysid: {
                type: 'hidden',
                name: 'kimlikfotokopisifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            gizliliksozlesmesifiledysid: {
                type: 'hidden',
                name: 'gizliliksozlesmesifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            mukimlikbelgesifiledysid: {
                type: 'hidden',
                name: 'mukimlikbelgesifiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            ekbelgefiledysid: {
                type: 'hidden',
                name: 'ekbelgefiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            qualityAssuranceandEmployeefiledysid: {
                type: 'hidden',
                name: 'qualityAssuranceandEmployeefiledysid',
                group: 1,
                sira: 0,
                edit: '',
                dontCompare: true,
            },
            email: {
                type: 'auto',
                name: 'email',
                label: 'FL_Email',
                group: 2,
                sira: 10,
                col: '6',
                edit: '01234X',
                parameters: ['*id'],
                outputs: {
                    yetkiliad: 'yetkiliad',
                    gsm: 'gsm'
                },
                itemText: ['yetkiliad', 'email', 'unvan'],
                searchText: 'email',
                validateMin: 1,
                minLength: 0,
            },
            yetkiliad: {
                type: 'text',
                name: 'yetkiliad',
                label: 'FL_CompanyRepresentative',
                group: 2,
                sira: 20,
                col: '6',
                edit: '01234X',
                validateMin: 1,
            },
            gsm: {
                type: 'text',
                name: 'gsm',
                label: 'FL_GSM',
                group: 2,
                sira: 30,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            tel: {
                type: 'text',
                name: 'tel',
                label: 'FL_PhoneNumber',
                group: 2,
                sira: 40,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            fax: {
                type: 'text',
                name: 'fax',
                label: 'FL_FaxNumber',
                group: 2,
                sira: 50,
                col: '4',
                edit: '01234X',
                mask: 'phone',
                validateMin: 10,
                validateMax: 10,
            },
            ulke: {
                type: 'auto',
                group: 2,
                sira: 60,
                col: '4',
                name: 'ulke',
                label: 'FL_Country',
                edit: '01234X',
                parameters: [],
                outputs: {
                    trulke: 'ulketr'
                },
                itemText: ['ulke'],
                searchText: 'ulke',
                validateMin: 1,
                minLength: 1,
                notAuthorizedRequest: true,
                queryName: 'tedarik_ulke'
            },
            il: {
                type: 'auto',
                group: 2,
                sira: 70,
                col: '4',
                name: 'il',
                label: 'FL_City',
                edit: '01234X',
                parameters: ['*ulke'],
                outputs: {
                    sehirkodu: 'sehirkodu',
                },
                itemText: ['sehirkodu', 'il'],
                searchText: 'il',
                validateMin: 1,
                minLength: 0,
            },
            ilce: {
                type: 'auto',
                group: 2,
                sira: 80,
                col: '4',
                name: 'ilce',
                label: 'FL_County',
                edit: '01234X',
                parameters: ['*ulke', '*il', '*sehirkodu'],
                outputs: {
                    ilce: 'ilce'
                },
                itemText: ['ilce'],
                searchText: 'ilce',
                validateMin: 1,
                minLength: 0,
            },
            adres: {
                type: 'textarea',
                group: 2,
                sira: 90,
                col: '12',
                name: 'adres',
                label: 'FL_Address',
                edit: '01234X',
                validateMin: 1,
            },
            districtBranchOfficeInformation: {
                type: 'textarea',
                group: 2,
                sira: 95,
                col: '12',
                name: 'districtBranchOfficeInformation',
                label: 'FL_DistrictBranchOfficeInformation',
                edit: '01234X'
            },

            accountingResponsible: {
                type: 'auto',
                name: 'accountingResponsible',
                label: 'FL_AccountingResponsible',
                group: 2,
                sira: 100,
                col: '6',
                edit: '01234X',
                parameters: ['*id'],
                outputs: {
                    id: 'accountingResponsibleId'
                },
                itemText: ['yetkiliad', 'email', 'unvan'],
                searchText: 'yetkiliad',
            },
            accountingResponsibleId: {
                group: 2,
                sira: 100,
                type: 'hidden',
                label: '',
                edit: '',
                name: 'accountingResponsibleId',
                width: 0
            },

            numberOfEmployee: {
                type: 'number',
                group: 5,
                sira: 1,
                col: '3',
                name: 'numberOfEmployee',
                label: 'FL_NumberOfEmployee',
                edit: '01234X',
                validateMin: 1,
            },

            highSchoolPercentage: {
                type: 'number',
                group: 5,
                sira: 5,
                col: '3',
                name: 'highSchoolPercentage',
                label: 'FL_HighSchoolPercentage',
                edit: '01234X',
            },
            womanPercentage: {
                type: 'number',
                group: 5,
                sira: 10,
                col: '3',
                name: 'womanPercentage',
                label: 'FL_WomanPercentage',
                edit: '01234X',
            },
            manPercentage: {
                type: 'number',
                group: 5,
                sira: 15,
                col: '3',
                name: 'manPercentage',
                label: 'FL_ManPercentage',
                edit: '01234X',
            },
            universityPercentage: {
                type: 'number',
                group: 5,
                sira: 16,
                col: '3',
                name: 'universityPercentage',
                label: 'FL_UniversityPercentage',
                edit: '01234X',
            },
            numberOfOutsourcePersonnel: {
                type: 'number',
                group: 5,
                sira: 20,
                col: '3',
                name: 'numberOfOutsourcePersonnel',
                label: 'FL_NumberOfOutsourcePersonnel',
                edit: '01234X',
            },
            diversityInformation: {
                type: 'select',
                name: 'diversityInformation',
                label: 'FL_DiversityInformation',
                group: 5,
                sira: 25,
                col: '6',
                edit: '01234X',
                multiple: true,
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1,
            },
            awards: {
                type: 'textarea',
                group: 5,
                sira: 30,
                col: '12',
                name: 'awards',
                label: 'TH_OwnedAwards',
                edit: '01234X',
            },
            //akbankhesabi: {
            //    type: 'select',
            //    name: 'akbankhesabi',
            //    label: 'Bu Bankada Hesabi Var mi',
            //    group: 3,
            //    sira: 20,
            //    col: '12',
            //    edit: '01234X',
            //    options: [
            //        //Filled At Created Method
            //    ],
            //    dontCompare: true,
            //    validateMin: 1,
            //},
            alertBank: {
                type: 'alert',
                group: 3,
                sira: 21,
                messageType: 'warning',
                message: 'IM_BankAccountMustBeCorrect',
                col: '12',
            },
            Tedarik_Firma_Hesap: {
                type: 'grid',
                group: 3,
                sira: 22,
                name: 'Tedarik_Firma_Hesap',
                label: 'TH_BankAccountInformation',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Firma_Swift_Hesap: {
                type: 'grid',
                group: 3,
                sira: 23,
                name: 'Tedarik_Firma_Swift_Hesap',
                label: 'TH_BankAccountSwiftInformation',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showaddrow: true,
                showExportToExcelButton: false
            },
            tedarik_insurances: {
                type: 'grid',
                group: 3,
                sira: 26,
                name: 'tedarik_insurances',
                label: 'TH_Insurances',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showaddrow: true,
                showExportToExcelButton: false,
            },
            Tedarik_Biggest_Customers: {
                type: 'grid',
                group: 4,
                sira: 3,
                name: 'Tedarik_Biggest_Customers',
                label: 'TH_BiggestCustomers',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Biggest_Financial_Customers: {
                type: 'grid',
                group: 4,
                sira: 5,
                name: 'Tedarik_Biggest_Financial_Customers',
                label: 'TH_FinancialCustomers',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                ],
                dontCompare: true,
                showaddrow: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Ortaklar: {
                type: 'grid',
                group: 4,
                sira: 10,
                name: 'Tedarik_Uyum_Ortaklar',
                label: 'TH_PartnershipStructure',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                    //{ text: 'Sil', color: 'red darken-2 white--text', icon: 'trash-can-outline', tooltip: 'Banka bilgilerinin başında bulunan kutucukları işaretleyerek istediğiniz hesapları silebilirsiniz.' }
                ],
                showaddrow: true,
                dontCompare: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Temsilciler: {
                type: 'grid',
                group: 4,
                sira: 10,
                name: 'Tedarik_Uyum_Temsilciler',
                label: 'TH_PersonsAuthorizedRepresent',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                    //{ text: 'Sil', color: 'red darken-2 white--text', icon: 'trash-can-outline', tooltip: 'Banka bilgilerinin başında bulunan kutucukları işaretleyerek istediğiniz hesapları silebilirsiniz.' }
                ],
                showaddrow: true,
                dontCompare: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Grup_Firmalari: {
                type: 'grid',
                group: 4,
                sira: 10,
                name: 'Tedarik_Uyum_Grup_Firmalari',
                label: 'TH_GroupCompaniesIfAny',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                    //{ text: 'Sil', color: 'red darken-2 white--text', icon: 'trash-can-outline', tooltip: 'Banka bilgilerinin başında bulunan kutucukları işaretleyerek istediğiniz hesapları silebilirsiniz.' }
                ],
                showaddrow: true,
                dontCompare: true,
                showExportToExcelButton: false
            },
            Tedarik_Uyum_Yonetim_Yapisi: {
                type: 'grid',
                group: 4,
                sira: 10,
                name: 'Tedarik_Uyum_Yonetim_Yapisi',
                label: 'TH_ManagementStructure',
                edit: '01234X',
                col: '12',
                showselect: true,
                buttons: [
                    //{ text: 'Sil', color: 'red darken-2 white--text', icon: 'trash-can-outline', tooltip: 'Banka bilgilerinin başında bulunan kutucukları işaretleyerek istediğiniz hesapları silebilirsiniz.' }
                ],
                showaddrow: true,
                dontCompare: true,
                showExportToExcelButton: false
            },
        },
        form: 'tedarik',
        formid: 0,
        formdata: {
            id: 0,
            kformsid: 0,
            state: '0',
            firmaad: '',
            yurtici: '',
            sahis: '',
            verginum: '',
            tedarikcitipi: '',
            kategorikey: '',
            tedarikcikullanimi: '',
            ticaretodasifile: '',
            vergilevhasifile: '',
            faaliyetalani: '',
            gsm: '',
            tel: '',
            fax: '',
            email: '',
            yetkiliad: '',
            ulke: '',
            ulketr: '',
            il: '',
            sehirkodu: '',
            ilce: '',
            adres: '',
            PropertyOwnershipId: 0,
            stockInExchangeMarketPercentage: 0,
            Tedarik_Firma_Hesap: [],
            Tedarik_Uyum_Ortaklar: []
        },
        viewName: 'tumu',
        viewparameter: '',

        files: ['ticaretodasifile', 'vergilevhasifile'],
        extdata: [Tedarik_Firma_Hesap, Tedarik_Uyum_Ortaklar, Tedarik_Uyum_Grup_Firmalari, Tedarik_Uyum_Temsilciler, Tedarik_Uyum_Yonetim_Yapisi, Tedarik_Biggest_Customers, Tedarik_Biggest_Financial_Customers, tedarik_insurances, Tedarik_Firma_Swift_Hesap],
        actions: ['Kaydet'],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import { all } from 'core-js/fn/promise';

    import Utilities from '@/Utilities.js'

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    function ad_AE(payload) {
        /*alert('AE')*/
    }

    export default {
        name: 'bilgilerim',
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: { type: String, default: 'tumu' },
            drawer: { type: Boolean, default: true },
            single: { type: Boolean, default: false },
        },
        data: function () {
            return {
                valid: false,
                lazy: true,
                viewname: 'tumu',
                form: 'tedarik',
                validateError: '',
                username: '',
                formname: 'tedarik',
                depouser: false,
                accountuser: false,
                propertyeditstates: '',
                tab: 'Tab-1',
                gformsdata: Object,
                isgformsdataloaded: false,
                draftfile: 'portal_73a65e1f-d89a-4fad-8cf7-70fd2cca9b8f__IFC_Doc.pdf',
                durum: ''
            };
        },
        computed: {
            GetState() {
                return this.$store.getters.GetFormState;
            },
        },
        methods: {
            SetGFormsData: function () {
                var formdata = this.GetFormData;

                Utilities.log("\\\\", formdata["kformsid"].toString().trim());
                var payload = {};
                payload.action = "GetTedarikData"
                payload.data = [{ id: formdata["kformsid"].toString().trim() }];
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        Utilities.log("Runtime  success result:", response);
                        this.gformsdata = response.data.runTimeResult[0];
                        this.durum = this.gformsdata["durum"];
                        this.compareWithGFormsData(this.gformsdata);

                        this.isgformsdataloaded = true;
                    })
                    .catch(err => {
                        this.isgformsdataloaded = true;
                        Utilities.log("Runtime error result:", err);
                    });
                this.$store.commit('SetFormActions', [{ label: 'AL_Save', action: 'Save' }, { label: 'AL_SendForApproval', action: 'Update' }]);

            },
            FormLoaded: function () {
                Utilities.log('FORM LOADED');
                var formdata = this.GetFormData;
                var groupName = formdata.GroupName;
                if (formdata.state != "0" || formdata.state != "1") {

                }
                if (formdata.state != "0" && formdata.state != "" && formdata.state != "1") {
                    var self = this;
                    var payload = {};
                    payload.form = 'irsaliye';
                    payload.formid = formdata.id;
                    payload.parameter = formdata.Barcode;
                    this.$store.dispatch('GetFormRoles', payload)
                        .then((response) =>
                        {
                            return;

                        })
                }
                else {
                    this.propertyeditstates = '012'
                }
            },

            Degistir: function (fieldname) {
                var payload = {};
                payload.attributeName = "approved";
                payload.fieldName = fieldname;
                payload.value = false;
                payload.tableName = "formdata";

                this.$store.commit('SetFieldAttributes', payload);
            },

            PageAction: function (action) {
                var err = [];
                if (action.action == 'Update') {
                    err = this.ValidateForm();
                }

                if (err.length > 0) {
                    var messages = {
                        errors: err,
                        infoBody: '',
                        messageType: 'E',
                        viewType: 'notification',
                    };
                    this.$root.$emit('setUpDialog', messages);

                    return;
                }
                var payload = {
                    form: 'tedarik',
                    formid: 0,
                    action: action,
                    viewName: ''
                };

                this.actionBarWait = true;
                var self = this;
                this.$store
                    .dispatch('SendFormAction', payload)
                    .then(response => {
                        self.actionBarWait = false;

                        if (response.data.errorCode == '0') {
                            var messages = {
                                errors: [this.$root.$i18n.t('DM_TransactionCompletedSuccessfully')],
                                infoBody: '',
                                messageType: 'B',
                                viewType: 'notification',
                            };
                            this.$root.$emit('setUpDialog', messages);

                            this.$router.push('/Anasayfa');
                        }
                        else {
                            var messages = {
                                errors: [this.$root.$i18n.t(response.data.errorMessage)],
                                infoBody: '',
                                messageType: 'E',
                                viewType: 'notification',
                            };
                            this.$root.$emit('setUpDialog', messages);
                        }

                    })
                    .catch(error => {
                        Utilities.log('FormAction catch error:', error);
                        self.actionBarWait = false;
                        var messages = {
                            errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                            infoBody: '',
                            messageType: 'E',
                            viewType: 'notification',
                        };
                        this.$root.$emit('setUpDialog', messages);
                    });
            },
            FormButtonClicked(col) {
                var name = col.name;
                if (name == 'ShowRequest') {
                    //Request formunu göster
                    //var url =
                    //    '/alterpi/saltalep/saltalep_frm.aspx?openformid=' +
                    //    this.GetFormField('RequestId');
                    //window.open(url);
                }
            },
            RowClicked: function (rowdata, table) {
                Utilities.log('clicked on table:', table, ' Row:', rowdata);
            },
            RowButtonClicked: function (payload, tableName) {
                Utilities.log(
                    ' Inventory Items RowButtonClicked',
                    payload,
                    ' Table:',
                    tableName
                );
                var button = payload.button.label;
                var id = payload.item.rowid;
                Utilities.log('Button on row:' + id + ' action:' + button);
                if (button == 'Sil')
                    this.$store.commit('RemoveRowFromExtData', {
                        tableName: tableName,
                        rowid: id
                    });
            },
            HeaderButtonClickedDyno(payload) {
                var tableName = payload.button.split(':::')[0];
                var buttonName = payload.button.split(':::')[1];
                var list = payload.list;

                Utilities.log('list >>>>> ', list);

                if (buttonName == 'Sil') {
                    for (var i = 0; i < list.length; i++) {
                        this.$store.commit('RemoveRowFromExtData', {
                            tableName: tableName,
                            rowid: list[i].rowid
                        });
                    }
                    this.message2 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                }
            },
            HeaderButtonClickedasdasd(payload, tableName) {
                var button = payload.button;
                var list = payload.list;
                if (button == 'Ekle->' && tableName == 'Inventory_Asset_data') {
                    for (var i = 0; i < list.length; i++) {
                        var parms = {
                            button: {
                                label: 'Ekle'
                            },
                            item: list[i]
                        };

                        var res = this.RowButtonClicked(parms, 'Inventory_Asset_data');
                        if (!res) return;
                    }
                    this.message1 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                } else if (button == '<-Çıkart' && tableName == 'AssetList') {
                    for (var i = 0; i < list.length; i++) {
                        this.$store.commit('RemoveRowFromExtData', {
                            tableName: tableName,
                            rowid: list[i].rowid
                        });
                    }
                    this.message2 = { action: 'deselect', value: 0, random: Utilities.GetRandomNumber() };
                }
            },
            ChangeAttribute(e, tableName) {

                var payload = {};
                payload.attributeName = e.attributeName;
                payload.fieldName = e.column.name;
                payload.value = e.value;
                payload.tableName = tableName;
                Utilities.log("FORM Emit changeAttribute", payload)
                this.$store.commit('SetFieldAttributes', payload);
            },
            IsLegitPhoneNumber: function (value) {
                return /^\d+$/.test(value);
            },
            CustomValidate: function (value, column) {
                if (column.name == 'gsm' || column.name == 'tel' || column.name == 'fax') {
                    var yurticiOryurtdisi = this.GetFormData['yurtici'].toString().trim();

                    if (yurticiOryurtdisi == '0') {
                        column.validateMax = 0;
                    }
                    else {
                        column.validateMax = 10;
                    }

                    if (column.name == 'tel' || column.name == 'fax') {
                        if (value.length > 0) {
                            return this.ValidateField(value, column);
                        }
                    }
                    else {
                        return this.ValidateField(value, column);
                    }
                }
                //else if (column.name == 'akbankhesabi')
                //{
                //    if (this.GetFormData['akbankhesabi'] == null || this.GetFormData['akbankhesabi'] == '') {
                //        return this.$root.$i18n.t('VE_AkbankHesabiYesNo');
                //    }
                //}
                else {
                    return this.ValidateField(value, column);
                }

                return '';
            },
            ValidateForm() {
                this.ClearFieldErrors();
                var errors = [];

                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;

                Utilities.log("formdata ::::: ", formdata);
                Utilities.log("columns ::::: ", columns);
                Utilities.log("GetExdataColumns ::::: ", GetExdataColumns);
                Utilities.log("GetExdataItems ::::: ", GetExdataItems);

                var needsCustomValidationFields = ['gsm', 'tel', 'fax', 'ticaretodasifile', 'vergilevhasifile'];
                var escapeFields = ['id', 'kformsid', 'ticaretodasifiledysid', 'vergilevhasifiledysid'];

                for (var c in columns) {
                    if (formdata[c] == null) formdata[c] = "";

                    var value = formdata[c].toString().trim();
                    var column = columns[c];
                    var attr = attributes[c];
                    var err = '';
                    if (column.type == 'file' && value == this.draftfile) {
                        value = '';
                    }

                    if (attr.disabled == false) {
                        if (needsCustomValidationFields.includes(column.name)) {
                            err = this.CustomValidate(value, column);
                        }
                        else if (!escapeFields.includes(column.name)) {
                            err = this.ValidateField(value, column);
                        }

                        if (err != '') {
                            errors.push(err);
                            this.SetFieldError(c);
                            this.tab = 'Tab-1';
                        }
                    }
                }

                if (errors.length == 0) {
                    var woman = parseFloat(formdata["womanPercentage"]);
                    var man = parseFloat(formdata["manPercentage"]);
                    if ((woman + man) != 100 && (man != 0 || woman != 0)) {
                        errors.push(this.$root.$i18n.t('VE_WarnWomanManPercentage'));
                    }
                }

                var GetExdataColumns = this.GetExdataColumns('Tedarik_Firma_Hesap');
                var GetExdataItems = this.GetExdataItems('Tedarik_Firma_Hesap');
                var ExtDataRowLen = GetExdataItems.length;

                var GetExdataSwiftItems = this.GetExdataItems('Tedarik_Firma_Swift_Hesap');
                var ExtDataSwiftRowLen = GetExdataSwiftItems.length;

                var defaultAccountSelectedCount = 0;
                for (var i = 0; i < ExtDataRowLen; i++) {
                    for (var col in GetExdataColumns) {
                        if (GetExdataItems[i][col] == undefined) GetExdataItems[i][col] = "";

                        var value = GetExdataItems[i][col].toString().trim();
                        var column = GetExdataColumns[col];
                        var err = this.ValidateField(value, column);


                        if (col == 'iban' && err == '' && value.substring(0, 2) != 'TR' && value.substring(0, 2) != 'tr') {
                            err = this.$root.$i18n.t('VE_WarnAccountStartWithTR');
                        }

                        var id = GetExdataItems[i]['rowid'];
                        if (err != '') {
                            errors.push(err);
                            this.SetExtDataFieldError('Tedarik_Firma_Hesap', id, col);
                            this.tab = 'Tab-3';
                        }
                        else {
                            this.ClearExtDataFieldError('Tedarik_Firma_Hesap', id, col);
                        }
                    }

                    if (GetExdataItems[i]['defaultHesap'].toString().trim() == 'E') {
                        defaultAccountSelectedCount++;
                    }
                }

                for (var i = 0; i < ExtDataSwiftRowLen; i++) {
                    if (GetExdataSwiftItems[i]['defaultHesap'].toString().trim() == 'E') {
                        defaultAccountSelectedCount++;
                    }
                }

                if ((ExtDataRowLen > 0 || ExtDataSwiftRowLen > 0) && defaultAccountSelectedCount == 0) {
                    var label = this.$root.$i18n.t('TH_BankAccountInformation');
                    if (ExtDataRowLen == 0 && ExtDataSwiftRowLen > 0) {
                        label = this.$root.$i18n.t('TH_BankAccountSwiftInformation');
                    }
                    this.tab = 'Tab-3';
                    errors.push(this.$root.$i18n.t('VE_WarnDefaultAccountSelection', { table: label }));
                }

                if ((ExtDataRowLen > 0 || ExtDataSwiftRowLen > 0) && defaultAccountSelectedCount > 1) {
                    this.tab = 'Tab-3';
                    errors.push(this.$root.$i18n.t('VE_WarnDefaultAccountSelectionOnlyOne', { table: label }));
                }

                var exdatas = dynoFormInfo.extdata;
                var isYurtici = this.GetFormField('yurtici') == "1";
                var hesapError = [];
                for (var j = 0; j < exdatas.length; j++) {
                    var exdata = exdatas[j];
                    var exdataColumns = this.GetExdataColumns(exdata.tableName);
                    var exdataItems = this.GetExdataItems(exdata.tableName);

                    if (exdataItems.length > 0) {
                        if (exdata.tableName != "Tedarik_Firma_Hesap") {
                            for (var i = 0; i < exdataItems.length; i++) {
                                for (var col in exdataColumns) {
                                    if (exdataItems[i][col] == undefined) exdataItems[i][col] = "";

                                    var value = exdataItems[i][col].toString().trim();
                                    var column = exdataColumns[col];

                                    var err;
                                    if (column.name == 'tckn') {
                                        if (isYurtici) {
                                            err = this.ValidateField(value, column);
                                        }
                                    }
                                    else {
                                        err = this.ValidateField(value, column);
                                    }

                                    var id = exdataItems[i]['rowid'];
                                    if (err != '') {
                                        errors.push(err);
                                        this.SetExtDataFieldError(exdata.tableName, id, col);
                                        if (exdata.tableName != "Tedarik_Firma_Swift_Hesap") {
                                            this.tab = 'Tab-2';
                                        }
                                        else {
                                            this.tab = 'Tab-3';
                                        }

                                    }
                                    else {
                                        this.ClearExtDataFieldError(exdata.tableName, id, col);
                                    }
                                }
                            }
                        }
                    }
                    else {
                        if (exdata.tableName == "Tedarik_Firma_Hesap" || exdata.tableName == "Tedarik_Firma_Swift_Hesap") {
                            hesapError.push(this.$root.$i18n.t(exdata.validationMessage));
                        }
                        else if (exdata.tableName != "Tedarik_Uyum_Grup_Firmalari" && exdata.tableName != "Tedarik_Biggest_Financial_Customers"
                            && exdata.tableName != "tedarik_insurances") {                            
                            errors.push(this.$root.$i18n.t(exdata.validationMessage));
                            this.tab = 'Tab-2';
                            this.SetFieldError(exdata.tableName);
                        }
                    }
                }

                if (hesapError.length == 2) {
                    errors.push(hesapError[0]);
                    errors.push(hesapError[1]);
                    this.tab = 'Tab-3';
                    this.SetFieldError('Tedarik_Firma_Hesap');
                }

                return errors;
            },
            alwaysDisabledFields: function () {
                this.SetFieldDisabled('yurtici');
                this.SetFieldDisabled('verginum');
                this.SetFieldDisabled('vergidaire');
                this.SetFieldDisabled('sahis');
                this.SetFieldDisabled('yetkiliad');
                this.SetFieldDisabled('gsm');

                if (this.GetFormField('yurtici') == "1") {
                    this.ClearFieldDisabled('firmaad');
                }
                else {
                    this.SetFieldDisabled('firmaad');
                }

                let payload = {};

                if (this.GetFormField('ulke') == 'TÜRKİYE') {
                    this.ClearFieldDisabled('il');

                    if (this.GetFormField('il') == '') {
                        this.SetFieldDisabled('ilce');
                        payload.ilce = '';
                    }
                    else {
                        this.ClearFieldDisabled('ilce');
                    }
                }
                else {
                    this.SetFieldDisabled('il');
                    this.SetFieldDisabled('ilce');

                    payload.il = '';
                    payload.ilce = '';
                }

                if (this.GetFormField('stockInExchangeMarketFlag') == 'E') {
                    this.ClearFieldDisabled('stockInExchangeMarketPercentage');
                }
                else {
                    this.SetFieldDisabled('stockInExchangeMarketPercentage');
                }
                this.$store.commit('SetFields', payload);
            },

            ulke_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('ulke') == 'TÜRKİYE') {
                    this.ClearFieldDisabled('il');
                    this.SetFieldDisabled('ilce');
                }
                else {
                    this.SetFieldDisabled('il');
                    this.SetFieldDisabled('ilce');
                }

                payload.il = '';
                payload.ilce = '';

                this.$store.commit('SetFields', payload);
            },
            il_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('il') == '') {
                    this.SetFieldDisabled('ilce');
                }
                else {
                    this.ClearFieldDisabled('ilce');
                }

                payload.ilce = '';

                this.$store.commit('SetFields', payload);
            },

            email_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('email') == '') {
                    payload.yetkiliad = '';
                    payload.gsm = '';
                    this.$store.commit('SetFields', payload);
                }
            },

            stockInExchangeMarketFlag_Field_Changed: function () {
                var payload = {};

                if (this.GetFormField('stockInExchangeMarketFlag') == 'E') {
                    this.ClearFieldDisabled('stockInExchangeMarketPercentage');
                }
                else {
                    this.SetFieldDisabled('stockInExchangeMarketPercentage');
                }
                this.$store.commit('SetFields', payload);
            },

            //akbankhesabi_Field_Changed: function () {
            //    if (this.GetFormField('akbankhesabi') == '1') {

            //    } else {

            //        var messages = {
            //            errors: [this.$root.$i18n.t('IM_OpenAkbankAccount')],
            //            infoBody: '',
            //            messageType: 'I',
            //            viewType: 'notification',
            //        };
            //        this.$root.$emit('setUpDialog', messages);
            //    }
            //},
            tedarikcikullanimi_Field_Changed: function () {
                if (this.GetFormField('tedarikcikullanimi') == 'Firma') {
                    if (this.GetFormField('yurtici') == "1") {
                        dynoFormInfo.Columns.ticaretsicilgazetesifile.validateMin = 1;
                        dynoFormInfo.Columns.vergilevhasifile.validateMin = 1;
                        dynoFormInfo.Columns.imzasirkulerifile.validateMin = 1;
                        dynoFormInfo.Columns.faaliyetbelgesifile.validateMin = 1;
                        if (this.GetFormField('sahis') == "1") {
                            dynoFormInfo.Columns.kimlikfotokopisifile.validateMin = 1;
                        }
                    } else {
                        dynoFormInfo.Columns.mukimlikbelgesifile.validateMin = 1;
                    }
                    dynoFormInfo.Columns.IFCfile.validateMin = 1;
                    dynoFormInfo.Columns.gizliliksozlesmesifile.validateMin = 1;
                } else {
                    dynoFormInfo.Columns.ticaretsicilgazetesifile.validateMin = 0;
                    dynoFormInfo.Columns.vergilevhasifile.validateMin = 0;
                    dynoFormInfo.Columns.imzasirkulerifile.validateMin = 0;
                    dynoFormInfo.Columns.faaliyetbelgesifile.validateMin = 0;
                    dynoFormInfo.Columns.kimlikfotokopisifile.validateMin = 0;
                    dynoFormInfo.Columns.mukimlikbelgesifile.validateMin = 0;
                    dynoFormInfo.Columns.IFCfile.validateMin = 0;
                    dynoFormInfo.Columns.gizliliksozlesmesifile.validateMin = 0;
                }
            },
            compareWithGFormsData: function (gformsdata) {
                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var gformdata = gformsdata;

                console.log("formdata :::::", formdata);

                for (var c in columns) {
                    var original_c = c;
                    var column = columns[c];

                    if (column.type == "file") c = c + "dysid"; //dosya yüklemeleri dys üzerinden yapılıyor. o alanların isimleri sonlarına dysid

                    var portal_data = String(formdata[c]).trim();
                    if (portal_data == "null" || portal_data == "undefined") portal_data = "";
                    var form_data = String(gformdata[c]).trim();
                    if (form_data == "null" || form_data == "undefined") form_data = "";

                    console.log("c:", c);
                    console.log("portal_data :::::", portal_data);
                    console.log("form_data   :::::", form_data);

                    if (!column.dontCompare && form_data != portal_data) {
                        Utilities.log("column_name :::::", c);
                        Utilities.log("portal_data :::::", portal_data);
                        Utilities.log("form_data   :::::", form_data);
                        this.Degistir(original_c);
                    }
                }
            },
        },

        created: function () {
            var lang = Utilities.GetCurrentLanguage();

            var columns = dynoFormInfo.Columns;
            var attributes = {};
            for (var key in columns) {
                if (columns.hasOwnProperty(key)) {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false,
                        approved: true,
                        hidden: false,
                        validateMin: 0,
                    };
                }
            }
            dynoFormInfo.attributes = attributes;

            dynoFormInfo.Columns.yurtici.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('OP_Abroad'), value: '0' },
                { text: this.$root.$i18n.t('OP_TurkeyBased'), value: '1' }
            ];

            dynoFormInfo.Columns.sahis.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('AL_Reject'), value: '0' },
                { text: this.$root.$i18n.t('AL_Yes'), value: '1' }
            ];

            dynoFormInfo.Columns.tedarikcikullanimi.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('OP_Company'), value: 'Firma' },
                { text: this.$root.$i18n.t('OP_PublicAssociation'), value: 'Kamu/Dernek' }
            ];

            Tedarik_Firma_Hesap.columns.defaultHesap.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('AL_Reject'), value: 'H' },
                { text: this.$root.$i18n.t('AL_Yes'), value: 'E' }
            ];

            Tedarik_Firma_Swift_Hesap.columns.defaultHesap.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('AL_Reject'), value: 'H' },
                { text: this.$root.$i18n.t('AL_Yes'), value: 'E' }
            ];

            Tedarik_Uyum_Temsilciler.columns.departmentId.options = [
                { text: this.$root.$i18n.t('OP_GeneralManager'), value: 1 },
                { text: this.$root.$i18n.t('OP_AssistantGeneralManager'), value: 2 },
                { text: this.$root.$i18n.t('OP_Sales'), value: 3 },
                { text: this.$root.$i18n.t('OP_Accounting'), value: 4 },
                { text: this.$root.$i18n.t('OP_Other'), value: 5 }
            ];

            dynoFormInfo.Columns.stockInExchangeMarketFlag.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('AL_Reject'), value: 'H' },
                { text: this.$root.$i18n.t('AL_Yes'), value: 'E' }
            ];

            //dynoFormInfo.Columns.akbankhesabi.options = [
            //    { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
            //    { text: this.$root.$i18n.t('AL_Reject'), value: '2' },
            //    { text: this.$root.$i18n.t('AL_Yes'), value: '1' }
            //];

            var self = this;
            var req = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarikci_tipi",
                notAuthorizedRequest: true
            };
            req.success = function (result) {
                if (result.data.errorCode == 0) {
                    if (lang == 'en') {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklamaEn,
                                value: obj.aciklama
                            };
                            dynoFormInfo.Columns.tedarikcitipi.options.push(listItem);
                        });
                    } else {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklama,
                                value: obj.aciklama
                            };
                            dynoFormInfo.Columns.tedarikcitipi.options.push(listItem);
                        });
                    }
                } else {
                    self.ShowDialog(self.$root.$i18n.t(result.data.errorMessage), 'E', 'dialog');
                }
            };
            req.error = function (err) {
                self.ShowDialog(self.$root.$i18n.t('EM_UnexpectedErrorTryAgain'), 'E', 'dialog');
            };

            this.$store.dispatch("GetLOV", req);

            var reqKategori = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarikci_kategorisi",
                notAuthorizedRequest: true
            };
            reqKategori.success = function (result) {
                if (result.data.errorCode == 0) {
                    if (lang == 'en') {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklamaEn,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.kategorikey.options.push(listItem);
                        });
                    } else {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklama,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.kategorikey.options.push(listItem);
                        });
                    }
                } else {
                    self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                }
            };
            reqKategori.error = function (err) {
                self.ShowDialog(self.$root.$i18n.t('EM_UnexpectedErrorTryAgain'), 'E', 'dialog');
            };

            this.$store.dispatch("GetLOV", reqKategori);

            var reqDiversity = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarikci_diversityInformation",
                notAuthorizedRequest: true
            };
            reqDiversity.success = function (result) {
                if (result.data.errorCode == 0) {
                    if (lang == 'en') {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklamaEn,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.diversityInformation.options.push(listItem);
                        });
                    } else {
                        result.data.listResult.forEach(function (obj) {
                            var listItem = {
                                text: obj.aciklama,
                                value: obj.key.toString(),
                            };
                            dynoFormInfo.Columns.diversityInformation.options.push(listItem);
                        });
                    }
                } else {
                    self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                }
            };
            reqDiversity.error = function (err) {
                self.ShowDialog(self.$root.$i18n.t('EM_UnexpectedErrorTryAgain'), 'E', 'dialog');
            };
            this.$store.dispatch("GetLOV", reqDiversity);
        },
        mounted: function () {
            var self = this;

            this.$store.commit('updateFormInfo', dynoFormInfo);
            this.$store.commit('SetFormData', dynoFormInfo.formdata);
            var payload = {};
            payload.action = "GetCompanyInfo"
            payload.data = [];
            this.$store.commit("SetFormLoadingStatus", true);
            this.$store.dispatch("RunTime", payload)
                .then(response => {
                    Utilities.log("Runtime GetCompanyInfo:", response);
                    this.$store.commit("SetFormLoadingStatus", false);
                    var companyInfo = response.data.runTimeResult[0];
                    //companyInfo.ulketr = companyInfo.ulketr;
                    this.$store.commit('SetFields', companyInfo);

                    this.SetGFormsData();

                    this.alwaysDisabledFields();

                    if (self.GetFormField('yurtici') == "1") {
                        Tedarik_Uyum_Ortaklar.columns.tckn.validateMin = 10;
                        Tedarik_Uyum_Grup_Firmalari.columns.tckn.validateMin = 10;
                        dynoFormInfo.Columns.Tedarik_Uyum_Ortaklar.validateMin = 1;
                        dynoFormInfo.Columns.mukimlikbelgesifile.validateMin = 0;
                        if (self.GetFormField('tedarikcikullanimi') == 'Kamu/Dernek') {
                            dynoFormInfo.Columns.ticaretsicilgazetesifile.validateMin = 0;
                            dynoFormInfo.Columns.vergilevhasifile.validateMin = 0;
                            dynoFormInfo.Columns.imzasirkulerifile.validateMin = 0;
                            dynoFormInfo.Columns.faaliyetbelgesifile.validateMin = 0;
                            dynoFormInfo.Columns.gizliliksozlesmesifile.validateMin = 0;
                            dynoFormInfo.Columns.IFCfile.validateMin = 0;
                        } else {
                            dynoFormInfo.Columns.ticaretsicilgazetesifile.validateMin = 1;
                            dynoFormInfo.Columns.vergilevhasifile.validateMin = 1;
                            dynoFormInfo.Columns.imzasirkulerifile.validateMin = 1;
                            dynoFormInfo.Columns.faaliyetbelgesifile.validateMin = 1;
                            dynoFormInfo.Columns.gizliliksozlesmesifile.validateMin = 1;
                            dynoFormInfo.Columns.IFCfile.validateMin = 1;
                        }
                    }
                    else {
                        Tedarik_Uyum_Ortaklar.columns.tckn.validateMin = 0;
                        Tedarik_Uyum_Grup_Firmalari.columns.tckn.validateMin = 0;
                        dynoFormInfo.Columns.Tedarik_Uyum_Ortaklar.validateMin = 0;
                        dynoFormInfo.Columns.mukimlikbelgesifile.validateMin = 1;
                        dynoFormInfo.Columns.ticaretsicilgazetesifile.validateMin = 0;
                        dynoFormInfo.Columns.vergilevhasifile.validateMin = 0;
                        dynoFormInfo.Columns.faaliyetbelgesifile.validateMin = 0;
                    }

                    if (self.GetFormField('sahis') == "1") {
                        dynoFormInfo.Columns.kimlikfotokopisifile.validateMin = 1;
                    } else {
                        dynoFormInfo.Columns.kimlikfotokopisifile.validateMin = 0;
                    }

                    if (self.GetFormField('PrensipApprove')) {
                        self.SetFieldDisabled('PrensipApprove');
                    } else {
                        self.ClearFieldDisabled('PrensipApprove');
                    }

                    var hesaplar = {};
                    hesaplar.tableName = 'Tedarik_Firma_Hesap';
                    hesaplar.data = response.data.runTimeResult[0].Tedarik_Firma_Hesap;
                    hesaplar.maxLen = 0;
                    self.$store.commit('SetExtdataData', hesaplar);

                    var hesaplar2 = {};
                    hesaplar2.tableName = 'Tedarik_Firma_Swift_Hesap';
                    hesaplar2.data = response.data.runTimeResult[0].Tedarik_Firma_Swift_Hesap;
                    hesaplar2.maxLen = 0;
                    self.$store.commit('SetExtdataData', hesaplar2);

                    var ortaklar = {};
                    ortaklar.tableName = 'Tedarik_Uyum_Ortaklar';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Uyum_Ortaklar;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);

                    var ortaklar = {};
                    ortaklar.tableName = 'Tedarik_Uyum_Grup_Firmalari';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Uyum_Grup_Firmalari;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);

                    var ortaklar = {};
                    ortaklar.tableName = 'Tedarik_Uyum_Temsilciler';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Uyum_Temsilciler;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);

                    var ortaklar = {};
                    ortaklar.tableName = 'Tedarik_Uyum_Yonetim_Yapisi';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Uyum_Yonetim_Yapisi;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);

                    var ortaklar = {};
                    ortaklar.tableName = 'Tedarik_Biggest_Customers';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Biggest_Customers;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);

                    var ortaklar = {};
                    ortaklar.tableName = 'Tedarik_Biggest_Financial_Customers';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Biggest_Financial_Customers;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);

                    var ortaklar = {};
                    ortaklar.tableName = 'tedarik_insurances';
                    ortaklar.data = response.data.runTimeResult[0].Tedarik_Insurances;
                    ortaklar.maxLen = 0;
                    self.$store.commit('SetExtdataData', ortaklar);
                });
        },
    };
</script>
